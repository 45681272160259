import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { map, Observable, tap } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from '@auth0/auth0-angular';

@Injectable({
  providedIn: 'root',
})
export class HasPermissionGuard implements CanActivate {
  private authService = inject(AuthService);
  private router = inject(Router);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
    const permission = route.data['permission'];
    return this.hasPermission(permission).pipe(
      tap((allowed) => {
        if (!allowed) {
          this.router.navigateByUrl('/not-found');
        }
      }),
    );
  }

  private getPermissions(): Observable<string[]> {
    return this.authService.getAccessTokenSilently().pipe(
      map((token) => {
        const jwtHelper = new JwtHelperService();
        return jwtHelper.decodeToken(token).permissions;
      }),
    );
  }

  private hasPermission(requiredPermission: string): Observable<boolean> {
    return this.getPermissions().pipe(
      map((permissions) => requiredPermission === '' || permissions.includes(requiredPermission)),
    );
  }
}
